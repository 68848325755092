var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[(!_vm.quoteData.formLoading)?_c('div',{staticClass:"box b-shdw-3",attrs:{"id":"quote-form"}},[_vm._m(0),_vm._m(1),_c('hr'),(_vm.policySystemErrors)?_c('div',{staticClass:"notification is-danger"},[_c('p',[_vm._v(_vm._s(_vm.policyCustomerErrors))])]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.quoteFn.apply(null, arguments)}}},[_c('quote-policy-type-selector',{attrs:{"quote-data":_vm.quoteData}}),_c('hr'),_c('quote-area-selector',{attrs:{"quote-data":_vm.quoteData}}),_c('hr'),_c('quote-dates-selector',{attrs:{"quote-data":_vm.quoteData},model:{value:(_vm.quoteData.dates),callback:function ($$v) {_vm.$set(_vm.quoteData, "dates", $$v)},expression:"quoteData.dates"}}),_c('hr'),_c('quote-relationship-selector',{attrs:{"quote-data":_vm.quoteData}}),_c('hr'),_c('quote-passengers',{attrs:{"quote-data":_vm.quoteData}}),(_vm.quoteData.paxAmount > 0)?_c('hr'):_vm._e(),_c('quote-activity-selector',{attrs:{"quote-data":_vm.quoteData}}),_c('hr'),_c('quote-endorsements',{attrs:{"quote-data":_vm.quoteData},model:{value:(_vm.quoteData.endorsements),callback:function ($$v) {_vm.$set(_vm.quoteData, "endorsements", $$v)},expression:"quoteData.endorsements"}}),_c('hr'),(_vm.quoteData.policyType?.includes('CR'))?_c('quote-total-trip-cost',{attrs:{"quote-data":_vm.quoteData}}):_vm._e(),(!this.affiliation || (this.affiliation && (this.affiliation.toLowerCase().includes('moneyfacts')
            || this.affiliation.toLowerCase().includes('shannon')
            || this.affiliation.toLowerCase().includes('jasmine')
            || this.affiliation.toLowerCase().includes('matthew')
            || this.affiliation.toLowerCase().includes('lynn'))))?_c('quote-promo-code-input',{attrs:{"quote-data":_vm.quoteData}}):_vm._e(),(!this.affiliation || (this.affiliation && (this.affiliation.toLowerCase().includes('moneyfacts')
            || this.affiliation.toLowerCase().includes('shannon')
            || this.affiliation.toLowerCase().includes('jasmine')
            || this.affiliation.toLowerCase().includes('matthew')
            || this.affiliation.toLowerCase().includes('lynn'))))?_c('hr'):_vm._e(),_c('quote-email',{attrs:{"quote-data":_vm.quoteData}}),_c('hr'),_c('b-checkbox',{model:{value:(_vm.quoteData.marketing),callback:function ($$v) {_vm.$set(_vm.quoteData, "marketing", $$v)},expression:"quoteData.marketing"}},[_vm._v(" We would love to stay in touch with you, but only to send useful and relevant information including discounts and offers - please tick this box to allow us to. Thank you. ")]),_c('hr'),_c('button',{staticClass:"button is-primary is-medium is-fullwidth hvr-icon-forward b-shdw-1",class:{'is-loading':_vm.quoteData.formLoading}},[_vm._v(" Get Quote "),_c('i',{staticClass:"fa fa-chevron-circle-right hvr-icon"})])],1)]):_c('div',{staticClass:"box b-shdw-3 level"},[_vm._m(2)]),_c('age-verification-modal',{attrs:{"ageVerificationModalActive":_vm.ageVerificationModalActive},on:{"modalActive":_vm.modalActive}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"title"},[_vm._v("Step 1: Get a Travel Insurance Quote")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center message"},[_c('h5',{},[_vm._v("Important Information:")]),_c('p',[_c('b',[_vm._v("For your cover to be valid, you")]),_vm._v(", and anyone you would like named on your policy must: "),_c('ul',[_c('li',[_c('img',{staticClass:"tick-img",attrs:{"src":"/img/tick-icon.png"}}),_vm._v(" Be a resident of the UK or Channel Islands ")]),_c('li',[_c('img',{staticClass:"tick-img",attrs:{"src":"/img/tick-icon.png"}}),_vm._v(" Have your trip starting and returning to the UK or Channel Islands ")]),_c('li',[_c('img',{staticClass:"tick-img",attrs:{"src":"/img/tick-icon.png"}}),_vm._v(" Have a permanent residential address in the UK or Channel Islands ")]),_c('li',[_c('img',{staticClass:"tick-img",attrs:{"src":"/img/tick-icon.png"}}),_vm._v(" Have unrestricted right of entry to the UK or Channel Islands ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-item"},[_c('div',{staticClass:"has-text-centered"},[_vm._v(" Calculating your quote... "),_c('div',{staticClass:"loader1 level"})])])
}]

export { render, staticRenderFns }